import { createContext, ReactNode, useState } from 'react'
import axios from 'axios'

import { useAuthentication } from '@motrix/authentication'
import { Loader } from '@motrix/core'

interface FrameworkBrowserContextData {
  open: boolean
  show: () => void
  hide: () => void
  fetchDescendants: (
    topicIds: Array<string>,
    grades: Array<{ id: string; title: string }>,
    standards: Array<{ id: string; title: string }>,
  ) => Promise<{ id: string; title: string; type: string; parent: string }[]>
}

const FrameworkBrowserContext = createContext<FrameworkBrowserContextData>(
  {} as FrameworkBrowserContextData,
)

export const FrameworkBrowserProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const frameworkUrl = process.env.FW_API_URL || ''
  const {
    me: { accessToken },
  } = useAuthentication()

  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const show = () => setOpen(true)

  const hide = () => setOpen(false)

  const fetchDescendants = async (
    topicIds: Array<string>,
    grades: Array<{ id: string; title: string }>,
    standards: Array<{ id: string; title: string }>,
  ) => {
    setIsLoading(true)
    const promises = topicIds.map(async (topicId) => {
      const request = axios.get(
        `${frameworkUrl}/topic/${topicId}/descendant-microskills?filter[standards]=${standards
          .map((standard) => standard.id)
          .join(',')}&filter[grades]=${grades
          .map((grade) => grade.id)
          .join(',')}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      )
      return request
    })
    const responses = await Promise.all(promises)
    const microSkills: Array<{
      id: string
      title: string
      type: string
      parent: string
    }> = responses
      .map((response) => {
        return response.data.data.map(
          (microSkill: { id: string; attributes: { title: string } }) => ({
            id: microSkill.id,
            parent: '',
            title: microSkill.attributes.title,
            type: 'micro-skill',
          }),
        )
      })
      .flat()

    setIsLoading(false)
    return microSkills
  }

  return (
    <FrameworkBrowserContext.Provider
      value={{
        fetchDescendants,
        hide,
        open,
        show,
      }}
    >
      {isLoading && <Loader isLoading />}
      {children}
    </FrameworkBrowserContext.Provider>
  )
}

export default FrameworkBrowserContext
