import {
  Curriculum,
  CurriculumApiResource,
  NewCurriculum,
  NewCurriculumApiResource,
} from '@domain/curriculum.type'

import { gradeFromAPI, gradeToAPI } from './grade'
import { microSkillFromAPI, microSkillToAPI } from './micro-skill'
import { sectionFromAPI, sectionToAPI } from './section'
import { standardFromAPI, standardToAPI } from './standard'
import { subjectFromAPI, subjectToAPI } from './subject'
import { topicFromAPI, topicToAPI } from './topic'

const curriculumFromAPI = (params: CurriculumApiResource): Curriculum => {
  const { attributes, relationships } = params

  return {
    author: attributes?.author || '',
    createdAt: attributes?.createdAt,
    description: attributes?.description,
    grades: attributes?.grades?.map(gradeFromAPI) ?? [],
    id: params.id,
    microSkills:
      relationships?.microSkills?.data?.map(microSkillFromAPI) ??
      attributes?.microSkills?.data?.map(microSkillFromAPI) ??
      [],
    microSkillsCount:
      attributes?.microSkillsCount ??
      attributes?.microSkills?.data?.length ??
      0,
    revisionId: attributes?.revisionId,
    rootIds: attributes?.rootIds ?? [],
    sections: relationships?.sections?.data?.map(sectionFromAPI) ?? [],
    standards: attributes?.standards?.map(standardFromAPI) ?? [],
    subjects: attributes?.subjects?.map(subjectFromAPI) ?? [],
    title: attributes?.title || '',
    topics: relationships?.topics?.data?.map(topicFromAPI) ?? [],
  }
}

const curriculumToAPI = (
  curriculum: Curriculum | NewCurriculum,
): CurriculumApiResource | NewCurriculumApiResource => {
  const data: CurriculumApiResource | NewCurriculumApiResource = {
    attributes: {
      description: curriculum.description,
      grades: curriculum.grades.map(gradeToAPI),
      rootIds: curriculum.rootIds,
      standards: curriculum.standards.map(standardToAPI),
      subjects: curriculum.subjects.map(subjectToAPI),
      title: curriculum.title,
    },
    id: curriculum.id,
    relationships: {
      microSkills: { data: [] },
    },
    type: 'curriculum',
  }

  const microSkills = curriculum?.microSkills?.map(microSkillToAPI)
  const sections = curriculum?.sections?.map(sectionToAPI)
  const topics = curriculum?.topics?.map(topicToAPI)

  const hasMicroSkills = !!microSkills && microSkills.length > 0
  const hasSections = !!sections && sections.length > 0
  const hasTopics = !!topics && topics?.length > 0

  if (hasMicroSkills) {
    data.relationships!.microSkills.data = microSkills
  }
  if (hasSections) {
    data.relationships!.sections = {
      data: sections,
    }
  }
  if (hasTopics) {
    data.relationships!.topics = {
      data: topics,
    }
  }

  return data
}

export { curriculumFromAPI, curriculumToAPI }
