import { Subject } from '@domain/index'

export type SubjectAPIParams = {
  id: string
  title: string
  links?: {
    self: string
  }
}

export type FrameworkSubjectParams = {
  id: string
  attributes: {
    title: string
  }
  links?: {
    self: string
  }
}

const subjectFromAPI = (params: SubjectAPIParams): Subject => {
  return {
    id: params.id,
    title: params.title,
  }
}

const subjectFromFramework = (grade: FrameworkSubjectParams): Subject => {
  return {
    ...grade.attributes,
    id: grade.id,
  }
}

const subjectToAPI = (subject: Subject): SubjectAPIParams => ({
  id: subject.id,
  links: { self: `https://api.motrix.global/subjects/${subject.id}` },
  title: subject.title,
})

export { subjectFromAPI, subjectFromFramework, subjectToAPI }
