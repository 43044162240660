import { Section, SectionApiResource } from '@domain/section.type'

const sectionFromAPI = (resource: SectionApiResource): Section => {
  const { id, type, attributes } = resource

  return {
    id,
    type,
    ...attributes,
    warnings: attributes.warnings ?? [],
  }
}

const sectionToAPI = (section: Section): SectionApiResource => {
  const { id, type, ...attributes } = section

  return {
    attributes,
    id,
    type,
  }
}

export { sectionFromAPI, sectionToAPI }
