import { Standard } from '@domain/index'

export type StandardAPIParams = {
  id: string
  title: string
  initials?: string
  links?: {
    self: string
  }
}

export type FrameworkStandardParams = {
  id: string
  attributes: {
    title: string
  }
  initials?: string
  links?: {
    self: string
  }
}

const standardFromAPI = (params: StandardAPIParams): Standard => {
  const standard: Standard = {
    id: params.id,
    title: params.title,
  }
  if (params.initials) {
    standard.initials = params.initials
  }

  return standard
}

const standardFromFramework = (params: FrameworkStandardParams): Standard => {
  const standard: Standard = {
    id: params.id,
    title: params.attributes.title,
  }
  if (params.initials) {
    standard.initials = params.initials
  }

  return standard
}

const standardToAPI = (standard: Standard): StandardAPIParams => {
  const standardAPIParams: StandardAPIParams = {
    id: standard.id,
    links: { self: `https://api.motrix.global/standards/${standard.id}` },
    title: standard.title,
  }
  if (standard.initials) {
    standardAPIParams.initials = standard.initials
  }

  return standardAPIParams
}

export { standardFromAPI, standardFromFramework, standardToAPI }
