import axios from 'axios'

import {
  errorInterceptor,
  requestBaseInterceptor,
  requestFwInterceptor,
  responseInterceptor,
} from './interceptors'

const baseURL = process.env.API_URL || ''
const frameWorkURL = process.env.FW_API_URL || ''

const createInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    params: {},
  })

  if (instance.defaults.baseURL === frameWorkURL) {
    instance.interceptors.request.use(requestFwInterceptor)
  } else {
    instance.interceptors.request.use(requestBaseInterceptor)
  }
  instance.interceptors.response.use(responseInterceptor, errorInterceptor)

  return instance
}

const api = createInstance(baseURL)
const frameWorkApi = createInstance(frameWorkURL)

export { api, frameWorkApi }
