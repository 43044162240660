const dateTime = (date: Date, locale = 'pt-BR') => {
  return `${date.toLocaleDateString(locale)}, ${date.toLocaleTimeString([], {
    hour12: false,
    timeStyle: 'short',
  })}`
}

const formatDate = {
  dateTime,
  onlyDate: (date: Date) => date.toLocaleDateString(),
  onlyTime: (date: Date) => date.toLocaleTimeString(),
}

export default formatDate
