import { Topic } from '@domain/topic.type'

import { GradeAPIParams, gradeFromAPI } from './grade'
import { Relationship } from './relationship'
import { StandardAPIParams, standardFromAPI } from './standard'
import { SubjectAPIParams, subjectFromAPI } from './subject'

export type TopicAPIParams = {
  type: 'topic'
  id: string
  attributes: {
    title: string
    parentId?: string
    subject?: SubjectAPIParams
    grades?: Array<GradeAPIParams>
    standards?: Array<StandardAPIParams>
  }
  links?: {
    self: string
  }
  relationships?: {
    grades?: Relationship<GradeAPIParams>
    standards?: Relationship<StandardAPIParams>
    subjects?: Relationship<SubjectAPIParams>
    subTopics?: Relationship<TopicAPIParams>
  }
}

const topicFromAPI = (params: TopicAPIParams): Topic => {
  const { attributes, relationships } = params

  return {
    grades: relationships?.grades?.data?.map(gradeFromAPI) ?? [],
    id: params.id,
    standards: relationships?.standards?.data?.map(standardFromAPI) ?? [],
    subjects: relationships?.subjects?.data?.map(subjectFromAPI) ?? [],
    title: attributes.title,
  }
}

const topicToAPI = (topic: Topic): TopicAPIParams => {
  return {
    attributes: {
      title: topic.title,
    },
    id: topic.id,
    links: { self: `https://api.motrix.global/topics/${topic.id}` },
    type: 'topic',
  }
}

export { topicFromAPI, topicToAPI }
