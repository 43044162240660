import { AxiosRequestConfig } from 'axios'

import { Auth } from '@motrix/authentication'

export const requestBaseInterceptor = async (request: AxiosRequestConfig) => {
  const currentSession = await Auth.currentSession()

  request.headers!.Authorization = `Bearer ${currentSession
    .getIdToken()
    .getJwtToken()}`
  request.headers!.accessToken = currentSession.getAccessToken().getJwtToken()

  return request
}

export const requestFwInterceptor = async (request: AxiosRequestConfig) => {
  const currentSession = await Auth.currentSession()

  request.headers!.Authorization = `Bearer ${currentSession
    .getAccessToken()
    .getJwtToken()}`

  return request
}
