import { css } from '@emotion/react'
import { MicroSkill } from '@motrix/core'
import { colors } from '@motrix/tokens'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { pluralize } from '@utils/index'

interface MicroSkillsCountProps {
  microSkillsCount: number
  size?: Size
}

const MicroSkillsCount = ({
  microSkillsCount,
  size = 'medium',
}: MicroSkillsCountProps) => (
  <Box css={microSkillsCountWrapper}>
    <MicroSkill color={colors.secondary01} size="3" />

    <Typography
      fontSize={sizes[size].fontSize}
      fontWeight={sizes[size].fontWeight}
    >
      {`${pluralize({
        amount: microSkillsCount,
        empty: 'Nenhuma micro-habilidade',
        plural: 'micro-habilidades',
        singular: 'micro-habilidade',
      })}`}
    </Typography>
  </Box>
)

type Size = 'large' | 'medium' | 'small'

type Attributes = {
  fontSize: number
  fontWeight: number
  iconHeight: number
  iconWidth: number
}

const sizes: { [variant in Size]: Attributes } = {
  large: {
    fontSize: 16,
    fontWeight: 500,
    iconHeight: 22,
    iconWidth: 20,
  },
  medium: {
    fontSize: 15,
    fontWeight: 500,
    iconHeight: 20,
    iconWidth: 16,
  },
  small: {
    fontSize: 13,
    fontWeight: 500,
    iconHeight: 15,
    iconWidth: 13,
  },
}

const microSkillsCountWrapper = css`
  align-items: center;
  display: inline-flex;
  justify-content: center;

  & > p {
    margin-inline-start: 6px;
  }
`

export default MicroSkillsCount
