import { Result } from '@badrap/result'
import {
  Curriculum,
  CurriculumApiResource,
  NewCurriculum,
} from '@domain/curriculum.type'
import { curriculumFromAPI, curriculumToAPI } from '@serializers/curriculum'
import { api } from '@services/api'

const getAll = async () => {
  try {
    const url = `/curriculums`
    const { data } = await api.get<{ data: CurriculumApiResource[] }>(url)

    const curriculums = data.data.map(curriculumFromAPI)

    return Result.ok({ curriculums })
  } catch (error) {
    return Result.err(new Error('Erro ao buscar os Currículos.'))
  }
}

const getById = async (
  curriculumId: string,
  params?: { [key: string]: string },
) => {
  try {
    let query = ''
    if (params) {
      query = '?'
      for (const key in params) {
        if (query !== '?') query += '&'
        query += `${key}=${params[key]}`
      }
    }
    const url = `/curriculums/${curriculumId}${query}`
    const { data } = await api.get<{ data: CurriculumApiResource }>(url)

    const curriculum = curriculumFromAPI(data.data)

    return Result.ok({ curriculum })
  } catch (error) {
    return Result.err(new Error('Erro ao buscar o Currículo.'))
  }
}

const create = async (curriculum: NewCurriculum) => {
  try {
    const url = `/curriculums`
    const { data } = await api.post<{ data: Curriculum }>(url, {
      data: curriculumToAPI(curriculum),
    })

    return Result.ok({
      curriculum: data,
      message: 'Currículo criado com sucesso.',
    })
  } catch (error) {
    return Result.err(new Error('Ops! Algo deu errado ao criar o Currículo.'))
  }
}

const updateById = async (
  curriculumId: string,
  curriculum: Partial<Curriculum>,
  revisionId: string,
): Promise<Result<any>> => {
  const url = `/curriculums/${curriculumId}?revisionId=${revisionId}`

  try {
    const { data } = await api.put(url, { data: curriculumToAPI(curriculum) })
    return Result.ok({ curriculum: curriculumFromAPI(data.data) })
  } catch (error: any) {
    console.log('error', error)
    const message =
      error?.response?.status === 409
        ? 'Não foi possível sincronizar o currículo pois existe uma versão mais recente'
        : 'Erro ao atualizar o Currículo.'

    return Result.err(new Error(message))
  }
}

const deleteById = async (curriculumId: string) => {
  try {
    const url = `/curriculums/${curriculumId}`
    await api.delete(url)

    return Result.ok({
      message: 'O Currículo foi excluído com sucesso.',
    })
  } catch (error) {
    return Result.err(new Error('Erro ao deletar o Currículo.'))
  }
}

export const curriculumService = {
  create,
  deleteById,
  getAll,
  getById,
  updateById,
}

export default curriculumService
