import { IconContainer } from '@motrix/core'
import { colors } from '@motrix/tokens'

import { IconProps } from './interfaces'

export const MicroSkill = ({
  color = colors.neutral09,
  size = '4',
  ...props
}: IconProps) => {
  return (
    <IconContainer {...props} size={size}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="7" r="3" fill={color} />
        <circle cx="12" cy="17" r="3" fill={color} />
        <circle cx="17" cy="12" r="3" fill={color} />
        <circle cx="7" cy="12" r="3" fill={color} />
      </svg>
    </IconContainer>
  )
}
