import { FrameworkGradeFromAPI } from '@domain/framework-micro-skill.type'
import { Grade } from '@domain/index'

export type GradeAPIParams = {
  id: string
  title: string
  links?: {
    self: string
  }
}

export type FrameworkGradeParams = {
  id: string
  attributes: {
    title: string
  }
  links?: {
    self: string
  }
  type: 'term'
}

const gradeFromAPI = (params: GradeAPIParams): Grade => {
  return {
    id: params.id,
    title: params.title,
  }
}

const gradeToAPI = (grade: Grade): GradeAPIParams => ({
  id: grade.id,
  links: { self: `https://api.motrix.global/grades/${grade.id}` },
  title: grade.title,
})

const gradeFromFramework = (grade: FrameworkGradeFromAPI): Grade => {
  return {
    ...grade.attributes,
    id: grade.id,
  }
}

export { gradeFromAPI, gradeFromFramework, gradeToAPI }
