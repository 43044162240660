import { FrameworkTopicApiResource } from '@domain/framework-topic.type'
import { Topic } from '@domain/topic.type'

import { gradeFromFramework } from './grade'
import { standardFromFramework } from './standard'
import { subjectFromFramework } from './subject'

const frameworkTopicFromApi = (resource: FrameworkTopicApiResource): Topic => {
  const { attributes, id, relationships, meta } = resource
  const relations = relationships?.relations?.map((relation) => ({
    id: relation.id,
    relationType: relation.attributes.relation_type,
    target: frameworkTopicFromApi(relation.attributes.target),
  }))

  const parents = meta?.parents.map((parentsPath) => {
    return parentsPath.map((parent) => ({
      grades: [],
      id: parent.id,
      standards: [],
      subjects: [],
      title: parent.attributes.title,
    }))
  })

  return {
    grades: attributes.grades.map(gradeFromFramework),
    id,
    parents: parents ?? [],
    relations: relations ?? [],
    standards: attributes.standards.map(standardFromFramework),
    subjects: [subjectFromFramework(attributes.subject)],
    title: attributes.title,
  }
}

export { frameworkTopicFromApi }
