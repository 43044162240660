import { AxiosError } from 'axios'

import { Auth } from '@motrix/authentication'

export const errorInterceptor = async (error: AxiosError) => {
  if (error.message === 'Network Error') {
    return Promise.reject(
      new Error(
        'Não foi possível conectar ao servidor. Verifique sua conexão com a internet.',
      ),
    )
  }

  if (error?.response?.status === 401) {
    await Auth.signOut()
  }

  return Promise.reject(error)
}
