import type { Curriculum } from '@domain/curriculum.type'
import { css } from '@emotion/react'
import { styled } from '@motrix/tokens'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'

interface ChipsStackProps {
  grades: Curriculum['grades']
  spacing?: number
  standards: Curriculum['standards']
  subjects: Curriculum['subjects']
  warningCount?: number
  isDetails?: boolean
}
const ChipsStack = ({
  grades,
  spacing = 8,
  standards,
  subjects,
  warningCount,
  isDetails = false,
}: ChipsStackProps) => {
  const theme = useTheme()

  const hasWarnings = !!warningCount && warningCount > 0

  return (
    <Stack css={chipsWrapperStyles(spacing)} direction="row">
      {standards &&
        standards.map(({ title, initials }) => (
          <Tag key={title} color={!isDetails ? 'standard' : 'detailsStandard'}>
            {initials ?? title}
          </Tag>
        ))}

      {subjects &&
        subjects.map(({ title }) => (
          <Tag key={title} color={!isDetails ? 'subject' : 'detailsSubject'}>
            {title}
          </Tag>
        ))}

      {grades &&
        grades.map(({ title }) => (
          <Tag key={title} color={!isDetails ? 'grade' : 'detailsGrade'}>
            {title}
          </Tag>
        ))}

      {hasWarnings && (
        <Chip
          label={`${warningCount} alertas`}
          size="small"
          sx={{
            backgroundColor: `${theme.palette.error.main}`,
            color: `${theme.colors.mainBackground}`,
            marginInlineStart: 'auto',
          }}
        />
      )}
    </Stack>
  )
}

const chipsWrapperStyles = (spacing: ChipsStackProps['spacing']) => css`
  column-gap: ${spacing}px;
  row-gap: ${spacing}px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const Tag = styled('div', {
  borderRadius: '$1',
  defaultVariants: {
    color: 'standard',
  },
  fontFamily: '$default',
  fontSize: '$xs',
  fontWeight: '$bold',

  padding: '$1 $2',
  variants: {
    color: {
      detailsGrade: {
        backgroundColor: '$neutral02',
        color: '$neutral06',
      },
      detailsStandard: {
        backgroundColor: '$neutral06',
        color: '$neutral00',
      },
      detailsSubject: {
        backgroundColor: '$neutral04',
        color: '$neutral06',
      },
      grade: {
        backgroundColor: '$primary02',
        color: '$primary06',
      },
      standard: {
        backgroundColor: '$primary07',
        color: '$neutral00',
      },
      subject: {
        backgroundColor: '$primary05',
        color: '$neutral00',
      },
    },
  },
})

export default ChipsStack
