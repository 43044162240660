import pick from 'lodash/pick'
import {
  MicroSkill,
  MicroSkillApiResource,
  MicroSkillRelationTarget,
} from 'types/micro-skill.type'

import {
  AssessmentCriteria,
  AssessmentCriteriaFromAPI,
  FrameworkMicroSkillResource,
  FrameworkMicroSkillsResource,
  FrameworkStandardRelation,
} from '@domain/framework-micro-skill.type'
import { FrameworkTopicApiResource } from '@domain/framework-topic.type'
import { StandardRelation, Term } from '@domain/standard.type'

import { frameworkTopicFromApi } from './framework-topic'
import { gradeFromFramework } from './grade'

export const microSkillFromAPI = (
  params: MicroSkillApiResource,
): MicroSkill => {
  const {
    id,
    type,
    attributes: { warningCount: _, ...attributes },
  } = params

  return {
    id,
    type,
    ...attributes,
    warnings: attributes.warnings ?? [],
  }
}

export const microSkillToAPI = (
  microSkill: MicroSkill,
): MicroSkillApiResource => {
  const { id, type } = microSkill
  const attributes = pick(microSkill, [
    'title',
    'description',
    'origin',
    'originId',
    'topicId',
    'topics',
  ])

  return {
    attributes: {
      ...attributes,
      warningCount: 0,
      warnings: [],
    },
    id,
    type,
  }
}

export const microSkillFromFramework = (
  params: FrameworkMicroSkillResource,
  bypassRelationTopics = false,
): MicroSkill => {
  const { id, attributes, relationships } = params
  const { topic, title, standards } = attributes

  const getRelationTarguet = (
    relation: any,
    bypassRelationTopics: boolean,
  ): MicroSkillRelationTarget => {
    const target = {
      fwSkillId: relation.attributes.target.id,
      id: relation.attributes.target.id,
      title: relation.attributes.target.attributes.title,
    }
    if (!bypassRelationTopics) {
      return {
        ...target,
        topic: frameworkTopicFromApi(
          relation.attributes.target.attributes.topic,
        ),
        topics: getTopicsFromMicroSkillFramework(
          relation.attributes.target.attributes.topic,
        ),
        verb: {
          id: relation.attributes.target.attributes.verb.id,
          title: relation.attributes.target.attributes.verb.attributes.title,
          type: 'term',
        },
      }
    }

    return target as MicroSkillRelationTarget
  }

  return {
    assessmentCriteria: attributes['assessment-criteria'].map(
      assessmentCriteriaFromAPI,
    ),
    grades: attributes.grades.map(gradeFromFramework) || [],
    id,
    origin: 'framework',
    originId: id,
    relations:
      relationships!.relations.map((relation: any) => ({
        id: relation.id,
        relationType: relation.attributes.relation_type,
        target: getRelationTarguet(relation, bypassRelationTopics),
      })) ?? [],
    standards: standards.map((standard) => ({
      id: standard.id,
      relations: getStandardRelationFromMicroSkillFramework(
        standard.id,
        relationships!['standard-relations'],
      ),
      title: standard.attributes.title,
    })),
    title,
    topic: {
      grades:
        topic.attributes.grades?.map((grade) => ({
          id: grade.id,
          title: grade.attributes.title,
        })) || [],
      id: topic.id,
      standards:
        topic.attributes.standards?.map((standard) => ({
          id: standard.id,
          title: standard.attributes.title,
        })) || [],
      subject: {
        id: topic.attributes.subject?.id!,
        title: topic.attributes.subject?.attributes?.title!,
      },
      subjects: [],
      title: topic.attributes.title,
    },
    topics: getTopicsFromMicroSkillFramework(topic),
    type: 'micro-skill',
    verb: {
      id: attributes.verb.id,
      title: attributes.verb.attributes.title,
      type: 'term',
    },
    warnings: [],
  }
}

const assessmentCriteriaFromAPI = (
  assessmentCriteria: AssessmentCriteriaFromAPI,
): AssessmentCriteria => {
  const { attributes, id, type } = assessmentCriteria
  const { grade } = attributes

  return {
    ...attributes,
    grade: {
      id: grade.id,
      title: grade.attributes.title,
      type: grade.type,
    },
    id,
    type,
  }
}

const getStandardRelationFromMicroSkillFramework = (
  standardId: string,
  relations: Array<FrameworkStandardRelation> = [],
): StandardRelation[] =>
  relations
    .filter((relation) => {
      const lastTermId =
        relation.attributes.target.meta?.parents.slice(-1)[0].id
      return lastTermId === standardId
    })
    .map((relation) => {
      const terms = relation.attributes.target.meta?.parents.map(
        (parent) =>
          ({
            parents: [],
            title: parent.attributes.title,
            type: 'term',
          } as Term),
      )
      terms?.pop()
      const lastTerm = {
        parents: terms,
        title: relation.attributes.target.attributes.title,
        type: 'term',
      } as Term
      return {
        terms: terms ? [...terms.reverse(), lastTerm] : [lastTerm],
        type: relation.attributes.relation_type,
      }
    })

const getTopicsFromMicroSkillFramework = (topic: FrameworkTopicApiResource) => {
  const firstTopicsPath = topic.meta?.parents
  const topics = firstTopicsPath?.map((parent) => {
    return parent.attributes.title
  })

  return topics
    ? [...topics.reverse(), topic.attributes.title]
    : [topic.attributes.title]
}

export const microSkillsFromFramework = (
  params: FrameworkMicroSkillsResource,
): MicroSkill[] => {
  return params.data.map((fwMicroSkill) => {
    return microSkillFromFramework(fwMicroSkill)
  })
}

export { getTopicsFromMicroSkillFramework }
