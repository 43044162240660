const pluralize = ({
  amount,
  empty,
  singular,
  plural,
}: {
  amount: number
  empty?: string
  singular: string
  plural: string
}) => {
  switch (amount) {
    case 0:
      return empty || `0 ${plural}`
    case 1:
      return `1 ${singular}`
    default:
      return `${amount} ${plural}`
  }
}

export default pluralize
